import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {
  Box,
  IconButton,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  getDefaultAlertProps,
  getSelectedLng,
  getViewType,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import {
  LANGUAGE_RUS,
  LEVEL_ADMIN,
  LEVEL_READ,
  MAIN_COLOR_STRING,
  PAGE_SIZE_DEVICES,
  PC_VIEW,
  ROOT_NAME,
  START_PAGE_SIZE_DEVICES,
  TOKEN_NAME,
} from '../../constant/constants';
import { DeviceListDto } from '../../dto/DeviceListDto';
import { apiGetDevices } from '../../service/device/apiGetDevices';
import { DialogInfo } from '../dialog/DialogInfo';
import { SIGN_IN_ROUTE } from '../../constant/routes';
import { MainDivItem } from './MainDivItem';

export const MainDiv = () => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const rootLevel = localStorage.getItem(ROOT_NAME) ?? '';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deviceDto, setDeviceDto] = useState<DeviceListDto>();
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const [deviceDtos, setDeviceDtos] = useState<DeviceListDto[]>([]);
  const getDevices = async () => {
    if (
      localStorage.getItem(TOKEN_NAME) !==
      'ssfsafhk23o8iulsahdoi727847o32iu25oiuoieauhdjs'
    ) {
      navigate(SIGN_IN_ROUTE);
      return;
    }
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetDevices();
    if (apiResult.isSuccess) {
      setDeviceDtos(apiResult.data.devices);
    } else {
      setAlertProps({
        message: t('error500'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const handleOpenDialog = (dto: DeviceListDto) => {
    setDeviceDto(dto);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'id',
      type: 'actions',
      width: 35,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipLook')}
            onClick={() => handleOpenDialog(row.row)}
          >
            <FindInPageIcon
              fontSize="small"
              sx={{ color: MAIN_COLOR_STRING }}
            />
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'procId',
      headerName: t('procId'),
      renderHeader: () => <strong>{t('procId')}</strong>,
      minWidth: 250,
      editable: false,
      renderCell: (row) => row.row.procId,
    },
    {
      field: 'cnt',
      headerName: t('cnt'),
      renderHeader: () => <strong>{t('cnt')}</strong>,
      minWidth: 250,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.cnt,
    },
    {
      field: 'resultWord',
      headerName: t('result'),
      renderHeader: () => <strong>{t('result')}</strong>,
      minWidth: 250,
      editable: false,
      hide: getViewType() !== PC_VIEW,

      renderCell: (row) => (
        <div style={{ color: row.row.result === '1' ? 'green' : 'red' }}>
          {row.row.resultWord}
        </div>
      ),
    },
    {
      field: 'datetime',
      headerName: t('datetime'),
      renderHeader: () => <strong>{t('datetime')}</strong>,
      minWidth: 250,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.datetime,
    },
    {
      field: 'note',
      headerName: t('note'),
      renderHeader: () => <strong>{t('note')}</strong>,
      minWidth: 250,
      flex: 1,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => row.row.note,
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%" paddingTop="20px" spacing={1}>
          {rootLevel === LEVEL_ADMIN && (
            <Box
              sx={{
                '& .app-theme-cell': {
                  fontFamily: 'sans-serif',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'datetime', sort: 'desc' }],
                    },
                  }}
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                      fontSize: '100px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '2px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '5px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={deviceDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...other}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={PAGE_SIZE_DEVICES}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          )}
          {rootLevel === LEVEL_READ && <MainDivItem dto={deviceDtos} />}
        </Stack>
      </Stack>
      <DialogInfo
        open={openDialog}
        deviceDto={deviceDto}
        onClose={handleCloseDialog}
      />
    </div>
  );
};
